@import 'styles/varibles.scss';

.header {
  width: 100%;
  position: relative;
  z-index: 20;
  padding: 0 12px;
  box-sizing: border-box;

  > div {
    margin: 0 auto;
    max-width: $screen-lg;
    display: flex;
    align-items: center;
    height: 64px;
    box-sizing: border-box;

    @media (max-width: $screen-xs) {
      width: auto;
    }
  }

  .logo {
    display: block;
    height: 28px;
    width: 28px;
    background: url('~assets/logo.svg') center no-repeat;
    background-size: contain;
  }

  .operator {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}
