.title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  margin: 30px 0 12px;
}

.currency {
  height: 39px;
  padding: 0 13px !important;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 21px;
  margin: 10px 0;

  .icon {
    width: 21px;
    height: 21px;
    margin-right: 5px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.link {
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  a {
    display: block;
    line-height: 1;
    margin-left: 8px;

    svg {
      vertical-align: top;
      font-size: 16px;
    }
  }
}

.addresses {
  display: inline-block;
}
