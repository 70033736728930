@import 'styles/varibles.scss';

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: $screen-lg;
  background: url('~assets/bg.png') no-repeat top center;

  @media (max-width: $screen-xs) {
    min-width: auto;
  }
}

.header {
  border-bottom: 1px solid #000;
}

.body {
  flex: 1;
  width: $screen-lg;
  margin: 0 auto;
  position: relative;
  z-index: 2;

  @media (max-width: $screen-xs) {
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;
  }
}

.error {
  color: red;
  text-align: center;
}

.wallet {
  .text {
    color: #fff;
    transition: color 0.3s, opacity 0.3s;
  }

  &:hover .text {
    color: transparent;
  }

  &:active .text {
    opacity: 0.5;
  }
}
