@import 'styles/varibles.scss';

.footer {
  position: relative;
  z-index: 2;
  width: $screen-lg;
  border-top: 1px solid #000;
  color: #000;
  margin: 0 auto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  box-sizing: border-box;
  padding: 20px 0 39px;

  @media (max-width: $screen-xs) {
    width: 100%;
    padding: 12px;
    border-top: none;
  }

  .copyright {
    flex: 1;
  }

  .links > a {
    color: #000;
    margin-left: 30px;
  }
}
