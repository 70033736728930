@import 'styles/varibles.scss';

.reject {
  text-align: center;

  @media (max-width: $screen-xs) {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  .icon {
    height: 54px;
    width: 54px;
    display: block;
    color: #ff295d;
    margin: 26px auto 21px;

    @media (max-width: $screen-xs) {
      width: 48px;
      height: 48px;
    }
  }

  .description {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000;
    margin: 0 0 50px;

    @media (max-width: $screen-xs) {
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 128px;
    }
  }
}

.success {
  text-align: center;

  @media (max-width: $screen-xs) {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  .icon {
    height: 54px;
    width: 54px;
    display: block;
    margin: 26px auto 21px;

    @media (max-width: $screen-xs) {
      width: 48px;
      height: 48px;
    }
  }

  .description {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin: 0 0 50px;

    @media (max-width: $screen-xs) {
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 128px;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    margin: 0 -6px;

    > div {
      padding: 0 6px;
      flex: 1;
    }

    .close {
      @media (max-width: $screen-xs) {
        display: none;
      }
    }
  }
}
