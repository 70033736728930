@import 'styles/varibles.scss';

.item {
  height: 54px;
  display: flex;
  align-items: center;
  width: 100%;
  border: 2px solid #000;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 0 20px;
  margin-bottom: 12px;
  background: transparent;
  transition: background-color 0.3s, opacity 0.3s;

  @media (max-width: $screen-xs) {
    height: 64px;
    border-radius: 6px;
    display: none;

    &.mobile {
      display: flex;
    }
  }

  &:hover {
    background: #f5f5f5;
  }

  &:active * {
    opacity: 0.5;
  }

  &:last-child {
    margin-bottom: 0;
  }

  span {
    text-align: left;
    flex: 1;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000;

    @media (max-width: $screen-xs) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .logo {
    width: 28px;
    height: 28px;

    @media (max-width: $screen-xs) {
      height: 32px;
      width: 32px;
    }
  }
}

.connecting {
  display: flex;
  align-items: center;
  height: 54px;
  padding: 0 15px;

  .icon {
    height: 24px;
    width: 24px;
    margin-right: 8px;
  }

  span {
    font-size: 16px;
    line-height: 20px;
  }
}

.error {
  display: flex;
  align-items: center;
  height: 54px;
  padding: 0 15px;
  border: 2px solid #ff295d;
  box-sizing: border-box;
  border-radius: 8px;

  .text {
    flex: 1;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #ff295d;
  }

  .line {
    width: 2px;
    height: 18px;
    background: #bfbfbf;
    border: none;
    margin: 0 15px;
  }

  button {
    padding: 0;
    background: none;
    border: none;
    color: #b47cff;
  }
}

.body {
  .logo {
    display: block;
    height: 56px;
    width: 56px;
    margin: 26px auto 15px;
  }

  .description {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #000;
    margin: 0 0 20px;
    text-align: center;

    a {
      color: #b47cff;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    padding: 16px;
    background: #f5f5f5;
    border-radius: 8px;
    margin-top: 12px;

    .context {
      flex: 1;
      text-align: left;
    }

    .title {
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color: #000;
      margin: 0 0 4px;
    }

    .text {
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: #000;
      margin: 0;
    }

    .icon {
      height: 36px;
      width: 36px;
    }
  }
}
