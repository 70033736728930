@import 'styles/varibles.scss';

.button {
  background: #000;
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #fff;
  border: 2px solid #000;
  height: 42px;
  padding: 0 16px;
  transition: background-color 0.3s;
  justify-content: center;

  &:not(.disabled) {
    &:hover,
    &:active {
      background: rgba(0, 0, 0, 0.85);
    }
  }

  @media (max-width: $screen-xs) {
    height: 36px;
    padding: 0 10px;
    border-radius: 6px;
    font-size: 12px;
    line-height: 15px;
  }

  &.large {
    height: 54px;
    font-size: 16px;
    line-height: 20px;

    @media (max-width: $screen-xs) {
      height: 48px;
    }
  }

  &.mini {
    height: 20px;
    font-size: 13px;
    line-height: 16px;
    padding: 2px 4px;
    border-radius: 4px;

    @media (max-width: $screen-xs) {
      height: 16px;
      font-size: 12px;
      padding: 0 4px;
    }
  }

  &.block {
    display: flex;
    width: 100%;
  }

  &.line {
    background: #fff;
    color: #000;

    &:hover,
    &:active {
      background: #f5f5f5;
    }
  }

  &.disabled {
    border-color: #f5f5f5;
    background: #f5f5f5;
    color: #7a7a7a;
  }

  .loading {
    height: 20px;
    width: 20px;
    margin-right: 13px;
  }
}
