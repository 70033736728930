.text {
  background: var(--color-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.border {
  background: linear-gradient(90.48deg, #ffd1c2 0%, #b47cff 100%);
  padding: 2px;
  position: relative;
  border-radius: 8px;
  box-sizing: border-box;

  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    background: #fff;
    z-index: 2;
    border-radius: 6px;
  }

  > * {
    position: relative;
    z-index: 3;
  }
}
