@import 'styles/varibles.scss';

.bg {
  background: url('~assets/home/color.png') no-repeat 30vw center;
  background-size: 1123px 760px;
  min-width: $screen-lg;

  @media (max-width: $screen-sm-max) {
    min-width: auto;
    background: url('~assets/home/color-mobile.png') no-repeat center 40px;
    background-size: 100vw 90vw;
  }
}

.home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('~assets/home/bg.png') center;
    opacity: 0.5;
  }

  > * {
    z-index: 3;
  }
}

.link:hover {
  opacity: 1;
}

.context {
  padding: 0 12px;
  margin: 0 -12px;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: $screen-sm-max) {
    flex-direction: column-reverse;
    justify-content: center;
    margin-bottom: 100px !important;
    padding: 0 20px;
  }

  .text {
    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 64px;
      line-height: 72px;
      margin: 0 0 32px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.01em;
      margin: 0 0 16px;
    }

    @media (max-width: $screen-sm-max) {
      h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 44px;
        line-height: 52px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.01em;
      }
    }
  }

  .image {
    min-width: 599px;
    height: 599px;
    background: url('~assets/home/image.png') no-repeat center;
    background-size: cover;

    @media (max-width: $screen-sm-max) {
      min-width: 80vw;
      height: 80vw;
      background-image: url('~assets/home/image-mobile.png');
      margin-bottom: 28px;
    }
  }
}

.main {
  width: $screen-lg;
  margin: 0 auto;

  @media (max-width: $screen-sm-max) {
    margin: 0 12px;
    width: auto;
  }
}

.footer {
  border-top: 1px solid #000;
  width: $screen-lg;
  margin: 0 auto;

  @media (max-width: $screen-sm-max) {
    margin: 0 12px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: auto;
  }
}

.header > div {
  @media (max-width: $screen-sm-max) {
    max-width: $screen-sm;
    width: auto;
  }
}
