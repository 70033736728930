.route {
  margin-bottom: 36px;

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 37px;
    margin: 0 0 24px;
  }

  .panel {
    background: #fff;
    border: 2px solid #000;
    box-sizing: border-box;
    border-radius: 28px;
    padding: 16px 24px;
    display: flex;

    .steps {
      flex: 1;
      border-top: 2px dashed #bfbfbf;
      margin-top: 15px;
      padding: 0 16px;
      display: flex;

      .item {
        flex: 1;
        margin-right: 12px;
        margin-top: -15px;

        &:last-child {
          margin-right: 0;
        }

        header {
          border-radius: 8px 8px 0px 0px;
          border: 1px solid #000;
          border-bottom: none;
          background: #fff;
          margin-bottom: 7px;
          display: flex;
          align-items: center;
          padding: 6px 8px 0;

          h4 {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            margin: 0;
            flex: 1;
          }

          .label {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            display: flex;
            align-items: center;

            .icon {
              margin-left: 4px;
              height: 12px;
              width: 12px;
            }
          }
        }

        .body {
          background: #f5f5f5;
          border-radius: 0px 0px 8px 8px;
          padding: 6px;
          text-align: right;

          .transform {
            display: flex;
            align-items: center;
            background: #fff;
            padding: 2px;
            border-radius: 20px;
            float: right;

            .icon {
              width: 16px;
              height: 16px;
            }

            .arrow {
              height: 8px;
              width: 8px;
              transform: rotate(90deg);
              margin: 0 2px;
            }
          }

          ul {
            clear: both;
            text-align: left;
            list-style: none;
            padding: 0;
            margin: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 14px;

            li {
              white-space: nowrap;
              margin-bottom: 4px;
              display: flex;
              align-items: center;

              &:last-child {
                margin-bottom: 0;
              }
            }

            .icon {
              height: 16px;
              width: 16px;
              margin-right: 4px;
            }

            .proportion {
              color: #7a7a7a;
              margin-left: 4px;
            }
          }
        }

        &.bridge {
          header {
            border-color: #b881fd;
            background: linear-gradient(180deg, #ffebef 0%, #ffffff 100%);
          }

          .body {
            background: linear-gradient(90.48deg, #ffd1c2 0%, #b47cff 100%);
          }
        }
      }
    }

    .from,
    .to {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #7a7a7a;

      p {
        margin: 8px 0 0;
      }
    }
  }
}
