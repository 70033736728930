@import 'styles/varibles.scss';

.page {
  margin: 70px 0;
  display: flex;

  @media (max-width: $screen-xs) {
    margin: 20px 0;
  }

  .left {
    flex: 1;
    margin-right: 120px;

    @media (max-width: $screen-xs) {
      display: none;
    }
  }

  .right {
    width: 392px;

    @media (max-width: $screen-xs) {
      width: 100%;
    }
  }
}

.panel {
  border: 2px solid #000;
  box-sizing: border-box;
  border-radius: 28px;
  padding: 0 24px 24px;
  background: #fff;

  @media (max-width: $screen-xs) {
    border-radius: 16px;
    padding: 0 20px 20px;
  }

  .title {
    padding: 20px 0 10px;
    margin: 0;
    border-bottom: 1px solid #000;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 100;

    > .text {
      flex: 1;
      font-weight: normal;
      color: #000;
      font-size: 30px;
      line-height: 37px;
    }

    @media (max-width: $screen-xs) {
      padding: 16px 0 12px;

      > .text {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .max {
    margin-left: 8px;
  }

  .body {
    padding: 20px 0 0;
  }

  .invert {
    padding: 0;
    margin: 12px 0 8px 16px;
    border: none;
    background: none;
    cursor: pointer;
    display: block;
    height: 30px;
    width: 30px;
    background: url('~assets/icons/invert.svg') center no-repeat;
    background-size: cover;

    &:hover {
      background-image: url('~assets/icons/invert-hover.svg');
    }

    &:active {
      background-image: url('~assets/icons/invert-active.svg');
    }
  }

  .order {
    margin-top: 20px;

    @media (max-width: $screen-xs) {
      margin-top: 12px;
    }
  }
}
