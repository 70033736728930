@import 'styles/varibles.scss';

.switch {
  height: 30px;
  width: 30px;
  cursor: pointer;
  position: relative;
  z-index: 5;
  background: url('~assets/icons/setting.svg') center no-repeat;
  background-size: cover;

  &:hover {
    background-image: url('~assets/icons/setting-hover.svg');
  }

  &:active {
    background-image: url('~assets/icons/setting-active.svg');
  }

  @media (max-width: $screen-xs) {
    height: 24px;
    width: 24px;
  }
}

.pc {
  @media (max-width: $screen-xs) {
    display: none;
  }
}

.mobile {
  display: none;

  @media (max-width: $screen-xs) {
    display: block;
  }
}

.popup {
  position: absolute;
  z-index: 4;
  right: -26px;
  top: -2px;
  padding-top: 55px;
  transition: opacity 0.3s, transform 0.3s;

  &.popup-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.popup-enter-active {
    opacity: 1;
    transform: translateY(0);
  }

  &.popup-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &.popup-exit-active {
    opacity: 0;
    transform: translateY(10px);
  }

  &.popup-exit-done {
    display: none;
  }

  .arrow {
    position: absolute;
    width: 78px;
    height: 55px;
    background: #f5f5f5;
    top: 0;
    right: 0;
    border-top: 2px solid #000;
    border-left: 2px solid #000;
    border-right: 2px solid #000;
    border-radius: 16px 16px 0 0;
  }

  .body {
    width: 392px;
    background: #f5f5f5;
    border: 2px solid #000;
    box-sizing: border-box;
    border-radius: 16px 0 16px 16px;
    padding: 20px 24px;
  }

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #000;
    margin: 0 0 20px;
    position: relative;
  }
}

.setting {
  @media (max-width: $screen-xs) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    margin: 20px 0 8px;
    display: flex;
    align-items: center;

    span {
      color: #7a7a7a;
      flex: 1;
      text-align: right;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .line {
    margin: 20px 0 0;
    height: 1px;
    border: none;
    background: #000;
  }

  .slippage {
    display: flex;

    .custom {
      flex: 1;
      width: 100%;
      margin-left: 12px;
      border: 2px solid #000000;
      box-sizing: border-box;
      border-radius: 8px;
      position: relative;
      overflow: hidden;

      input {
        width: 100%;
        border: none;
        box-sizing: border-box;
        height: 100%;
        padding: 0 14px;
        text-align: right;
        font-size: 13px;
        line-height: 16px;
        outline: none;

        &::placeholder {
          color: #bfbfbf;
        }
      }

      &.active {
        input {
          padding-right: 28px;
        }

        &:after {
          position: absolute;
          content: '%';
          z-index: 3;
          top: 11px;
          right: 12px;
          font-size: 13px;
          line-height: 16px;
          color: #bfbfbf;
        }
      }
    }
  }

  .speeds {
    display: flex;
    margin: 0 -6px;

    .item {
      flex: 1;
      border: 2px solid #000;
      border-radius: 8px;
      margin: 0 6px;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: #000;
      height: 42px;
      box-sizing: border-box;
      line-height: 38px;
      text-align: center;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;

      &.active {
        background: #000;
        color: #f5f5f5;
      }

      input {
        display: none;
      }
    }
  }

  .exchanges {
    height: 0;
    overflow-y: auto;
    transition: height 0.3s;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: $screen-xs) {
      flex: 1 0 auto;
    }

    .item {
      width: 50%;
      margin-bottom: 4px;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 24px;
    }

    &.expanded {
      height: 136px;
    }
  }

  .icon {
    display: block;
    height: 16px;
    width: 16px;
    background: url('~assets/icons/arrow.svg') no-repeat center;
    background-size: cover;
    margin-left: 10px;
    cursor: pointer;
    transition: transform 0.3s;

    &.expanded {
      transform: rotate(180deg);
    }

    @media (max-width: $screen-xs) {
      display: none;
    }
  }
}
