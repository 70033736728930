@import 'styles/varibles.scss';

.container {
  position: fixed;
  z-index: 3001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: $screen-xs) {
    width: 100%;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
  }
}

.modal {
  border: 2px solid #000;
  box-sizing: border-box;
  border-radius: 28px;
  overflow: hidden;
  background: #fff;
  width: 392px;
  position: relative;

  @media (max-width: $screen-xs) {
    width: 100%;
    border-radius: 16px 16px 0px 0px;
    border: none;
    height: 78vh;
    display: flex;
    flex-direction: column;
  }

  .close {
    display: block;
    height: 16px;
    width: 16px;
    border: none;
    background: url('~assets/icons/close.svg') no-repeat center;
    background-size: cover;
    cursor: pointer;
    position: absolute;
    top: 28px;
    right: 28px;

    @media (max-width: $screen-xs) {
      height: 14px;
      width: 14px;
      right: 20px;
      top: 20px;
    }
  }

  .title {
    border-bottom: 1px solid transparent;
    margin: 0 24px;
    padding: 20px 0 10px;
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    color: #000;

    @media (max-width: $screen-xs) {
      font-size: 16px;
      line-height: 24px;
      padding: 16px 0 12px;
      margin: 0 20px;
      border-bottom-color: #000;
      font-weight: normal;
    }
  }

  .body {
    padding: 0px 24px;

    @media (max-width: $screen-xs) {
      padding: 0px 20px;
      flex: 1;
    }
  }

  &.line {
    .title {
      border-bottom-color: #000;
    }

    .body {
      padding: 24px;

      @media (max-width: $screen-xs) {
        padding: 20px;
      }
    }
  }

  &.modal-enter {
    opacity: 0;
    transform: scale(0.9);

    @media (max-width: $screen-xs) {
      transform: scale(1);
      bottom: -80px;
    }
  }

  &.modal-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.3s, transform 0.3s, bottom 0.3s;

    @media (max-width: $screen-xs) {
      transform: scale(1);
      bottom: 0;
    }
  }

  &.modal-exit {
    opacity: 1;
    transform: scale(1);

    @media (max-width: $screen-xs) {
      transform: scale(1);
      bottom: 0;
    }
  }

  &.modal-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.3s, transform 0.3s, bottom 0.3s;

    @media (max-width: $screen-xs) {
      transform: scale(1);
      bottom: -80px;
    }
  }
}
