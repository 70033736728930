@import 'styles/varibles.scss';

.label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #7a7a7a;
  vertical-align: top;
  margin: 0;
}

.rate {
  margin-top: 8px;
  cursor: pointer;
  font-family: DM Mono;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  text-decoration: underline;

  @media (max-width: $screen-xs) {
    margin-top: 6px;
    font-size: 12px;
    line-height: 16px;
  }
}

.price {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;

  @media (max-width: $screen-xs) {
    margin-top: 12px;
  }

  td {
    padding: 2px 0;

    &:last-child {
      text-align: right;
      font-family: DM Mono;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.source {
  margin-bottom: 20px;

  @media (max-width: $screen-xs) {
    margin-bottom: 12px;
  }

  .list {
    background: #e6e6e6;
    border-radius: 8px;
    padding: 0;
    margin: 8px 0 0;
    list-style: none;
    padding: 3px;
    overflow: hidden;

    @media (max-width: $screen-xs) {
      margin-top: 6px;
    }

    li {
      padding: 5px;
      font-size: 13px;
      line-height: 16px;
      color: #000;
      float: left;
      font-size: 13px;
      line-height: 16px;

      @media (max-width: $screen-xs) {
        font-size: 12px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .proportion {
    display: inline-block;
    background: #000;
    border-radius: 5px;
    padding: 2px 4px;
    margin-left: 4px;
    font-size: 13px;
    line-height: 16px;

    @media (max-width: $screen-xs) {
      font-size: 12px;
    }
  }
}
