@import 'styles/varibles.scss';

.order {
  .info {
    position: relative;
    border: 2px solid #000;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;

    @media (max-width: $screen-xs) {
      padding: 12px 15px;
    }

    .line {
      margin: 15px 0 15px 48px;
      border: none;
      height: 1px;
      background: #000;

      @media (max-width: $screen-xs) {
        margin: 11px 0 11px 36px;
      }
    }

    .item {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 15px;
        height: 32px;
        width: 32px;
        border-radius: 50%;

        @media (max-width: $screen-xs) {
          height: 24px;
          width: 24px;
        }
      }
    }

    .coin {
      color: #000;
      min-width: 65px;

      h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        margin: 0 0 6px;

        @media (max-width: $screen-xs) {
          margin: 0 0 1px;
        }
      }

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
        margin: 0;

        @media (max-width: $screen-xs) {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }

    .amount {
      font-family: DM Mono;
      color: #000;
      flex: 1;
      text-align: right;

      h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        margin: 0 0 4px;

        @media (max-wdith: $screen-xs) {
          font-size: 20px;
          line-height: 24px;
          margin: 0 0 2px;
        }
      }

      p {
        margin: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;

        span {
          color: #7a7a7a;
          text-decoration: underline;
          margin-left: 8px;
        }
      }
    }

    .swap {
      position: absolute;
      font-size: 24px;
      color: #bfbfbf;
      top: 50%;
      margin-top: -11px;
      left: 19px;

      @media (max-width: $screen-xs) {
        font-size: 20px;
        left: 17px;
      }
    }
  }

  .footer {
    margin-top: 20px;
  }
}

.sending {
  text-align: center;

  @media (max-width: $screen-xs) {
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .icon {
    display: block;
    margin: 24px auto 20px;
    height: 56px;
    width: 56px;

    @media (max-width: $screen-xs) {
      height: 48px;
      width: 48px;
      margin-bottom: 40px;
    }
  }

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000;
    margin: 0 0 12px;

    @media (max-width: $screen-xs) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin: 0;

    @media (max-width: $screen-xs) {
      font-size: 13px;
      line-height: 16px;
    }

    span {
      font-family: DM Mono;
      font-weight: 500;
      text-decoration: underline;
      margin: 0 5px;
    }
  }

  .button {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #000;
    margin-top: 24px;

    @media (max-width: $screen-xs) {
      margin-top: 112px;
    }
  }
}
