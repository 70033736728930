@import 'styles/varibles.scss';

.modal {
  text-align: center;
  padding: 24px;

  @media (max-width: $screen-xs) {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  .icon {
    width: 56px;
    height: 56px;
    display: block;
    margin: 0 auto 20px;

    @media (max-width: $screen-xs) {
      width: 48px;
      height: 48px;
    }
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000;
    margin: 0;

    @media (max-width: $screen-xs) {
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 128px;
    }
  }
}
