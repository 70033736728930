@import 'https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap';
@import './open-sauce-sans.scss';
@import './varibles.scss';

html,
body {
  padding: 0;
  margin: 0;
  background: var(--body-background-color);
  font-family: 'Open Sauce One', monospace;
}

button {
  font-family: 'Open Sauce One', monospace;
  cursor: pointer;

  @media (max-width: $screen-xs) {
    padding: 0;
    color: inherit;
  }
}

input,
select {
  font-family: 'Open Sauce One', monospace;
  border-radius: 0;
}

a {
  text-decoration: none;
  transition: opacity 0.3s;
  color: #000;

  &:hover {
    opacity: 0.4;
  }
}

input {
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}
