@import 'styles/varibles.scss';

.canvas {
  display: none;
}

.avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.toggle {
  .address {
    margin-right: 8px;
  }
}

.balance {
  font-family: Open Sauce One;
  font-size: 13px;
  line-height: 15px;
  border-right: 2px solid rgba(255, 255, 255, 0.3);
  padding-right: 10px;
  margin-right: 10px;

  @media (max-width: $screen-xs) {
    display: none;
  }
}

.account {
  @media (max-width: $screen-xs) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .logo {
    position: relative;
    width: 64px;
    height: 64px;
    margin: 26px auto 12px;

    @media (max-width: $screen-xs) {
      width: 48px;
      height: 48px;
      margin: 0 auto 8px;
    }

    .icon {
      width: 100%;
      height: 100%;
      display: block;
    }

    .done {
      position: absolute;
      top: -2px;
      right: -2px;
      width: 20px;
      height: 20px;

      @media (max-width: $screen-xs) {
        width: 16px;
        height: 16px;
      }
    }
  }

  .description {
    margin: 0 0 20px;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000;
    text-align: center;

    @media (max-width: $screen-xs) {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
    }
  }

  .address {
    height: 54px;
    border: 2px solid #000000;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    padding: 0 15px;
    align-items: center;

    .avatar {
      height: 24px;
      width: 24px;
    }

    .text {
      flex: 1;
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;
      margin-left: 10px;

      @media (max-width: $screen-xs) {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
      }
    }

    hr {
      width: 2px;
      height: 18px;
      background: #bfbfbf;
      border: none;
      margin: 0 15px;
    }

    button {
      padding: 0;
      border: none;
      background: none;
      font-size: 13px;
      line-height: 16px;
      color: #b47cff;
      min-width: 90px;
    }
  }

  .operations {
    display: flex;
    margin-top: 20px;

    > div {
      flex: 1;
      width: 500%;

      &:first-child {
        padding-right: 6px;
      }

      &:last-child {
        padding-left: 6px;
      }
    }

    .button {
      width: 100%;

      @media (max-width: $screen-xs) {
        height: 42px;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }

  .line {
    background: #000;
    height: 1px;
    margin: 20px 0;
    border: none;
  }

  .empty {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 0;
    color: #bfbfbf;

    @media (max-width: $screen-xs) {
      font-size: 13px;
      line-height: 16px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .tsx {
    .title {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #7a7a7a;
      margin: 0;
    }

    .list {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin-top: 8px;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #000000;
        display: flex;
        align-items: center;

        @media (max-width: $screen-xs) {
          display: flex;
          background: #f5f5f5;
          border-radius: 6px;
          padding: 12px 16px;

          span {
            flex: 1;
          }
        }

        strong {
          text-decoration: underline;
          margin: 0 5px;
          font-weight: normal;

          @media (max-width: $screen-xs) {
            flex: 1;
          }
        }

        .link {
          cursor: pointer;
        }
      }
    }
  }
}
