@import 'styles/varibles.scss';

.currency {
  border: 2px solid #000;
  box-sizing: border-box;
  border-radius: 8px;
  height: 76px;
  display: flex;
  align-items: center;
  padding: 0 13px;
  position: relative;

  input {
    flex: 1;
    width: 100%;
    text-align: right;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 34px;
    font-weight: 300;
    border: none;
    outline: none;
    font-family: DM Mono;
    padding: 0;

    &:disabled {
      background: transparent;
    }
  }

  .price {
    position: absolute;
    font-size: 12px;
    line-height: 16px;
    color: #000;
    right: 14px;
    bottom: -8px;
  }

  @media (max-width: $screen-xs) {
    height: 64px;
    border-radius: 6px;

    input {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.select {
  margin-right: 10px;
  border: none;
  outline: none;
  background: none;
  display: flex;
  align-items: center;

  .icon {
    height: 32px;
    width: 32px;
    margin-right: 16px;
  }

  .default {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
  }

  .name {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-align: left;

    .network {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .arrow {
    display: block;
    height: 16px;
    width: 16px;
    background: url('~assets/icons/arrow.svg') no-repeat center;
    background-size: cover;
    margin-left: 6px;
    transform: rotate(180deg);
    position: relative;
    top: 1px;
  }

  @media (max-width: $screen-xs) {
    font-size: 18px;
    line-height: 24px;

    .icon {
      height: 24px;
      width: 24px;
    }

    .arrow {
      height: 12px;
      width: 12px;
      margin-left: 5px;
    }
  }
}

.label {
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 8px;
  align-items: center;

  @media (max-width: $screen-xs) {
    margin-bottom: 6px;
  }

  .balance {
    flex: 1;
    text-align: right;
    color: #7a7a7a;
  }
}

.currencies {
  height: 486px;
  display: flex;
  flex-direction: column;

  @media (max-width: $screen-xs) {
    height: 100%;
  }

  .network {
    padding: 20px 0 4px;
    margin: 0 -4px;

    button {
      margin: 4px;
      padding: 7px 10px;
      font-size: 13px;
      line-height: 24px;
      display: inline-flex;
      align-items: center;
      vertical-align: top;
      border-radius: 20px;
      border: none;
      background: #f5f5f5;

      &.active {
        background: #000;
        color: #fff;
      }

      .icon {
        height: 16px;
        width: 16px;
        margin-right: 5px;
      }
    }
  }

  .search {
    position: relative;

    input {
      font-size: 16px;
      line-height: 20px;
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      padding: 0;
      height: 54px;
      border: none;
      border-bottom: 1px solid #000;
      padding: 0 15px 0 24px;
      box-sizing: border-box;

      &:focus {
        border-color: #000;
      }

      ::placeholder {
        color: #7a7a7a;
      }

      @media (max-width: $screen-xs) {
        height: 60px;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .icon {
      position: absolute;
      left: 0;
      top: 50%;
      height: 16px;
      width: 16px;
      margin-top: -9px;
      color: #7a7a7a;
    }
  }

  .empty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #bfbfbf;
  }

  .loading {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 20px;

    .icon {
      width: 45px;
      height: 45px;
    }

    p {
      margin: 20 0 0;
    }
  }

  .list {
    flex: 1 1 auto;
    overflow-y: auto;
    margin: 0 -24px;
    position: relative;
    height: 0;

    .item {
      height: 70px;
      display: flex;
      padding: 0 24px;
      align-items: center;
      transition: background-color 0.3s;
      cursor: pointer;
      position: relative;

      @media (max-width: $screen-xs) {
        height: 64px;
        padding: 0 20px;
      }

      &::after {
        position: absolute;
        content: '';
        display: block;
        height: 1px;
        background: #bfbfbf;
        bottom: 0;
        left: 70px;
        right: 24px;

        @media (max-width: $screen-xs) {
          left: 56px;
          right: 20px;
        }
      }

      &:hover {
        background: #f5f5f5;
      }

      &:last-child {
        &::after {
          content: none;
        }
      }

      .logo {
        width: 32px;
        height: 32px;

        @media (max-width: $screen-xs) {
          height: 24px;
          width: 24px;
        }
      }

      .info {
        flex: 1;
        padding: 0 12px;

        h3 {
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          margin: 0 0 2px;

          @media (max-width: $screen-xs) {
            font-size: 14px;
            line-height: 20px;
            margin: 0;
          }
        }

        p {
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: #7a7a7a;
          margin: 0;

          @media (max-width: $screen-xs) {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }

      .balance {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #000;
      }
    }
  }
}
