$unit: 5px;
$screen-lg: 1114px;
$screen-md: 840px;
$screen-sm: 720px;
$screen-xs: 540px;
$screen-md-max: $screen-lg + $unit;
$screen-md-min: $screen-md + $unit;
$screen-sm-max: $screen-md-min - 1;
$screen-sm-min: $screen-xs + $unit;

:root {
  --body-background-color: #f5f5f5;
  --color-gradient: linear-gradient(90.48deg, #ffd1c2 0%, #b47cff 100%);
}

:root[theme='dark'] {
  --body-background-color: #000;
}
