.tabs {
  padding: 5px 2px;
  background: #000;
  border-radius: 8px;
  display: inline-flex;

  &.light {
    background: #e6e6e6;

    .item {
      background: #e6e6e6;
      color: #000;
      border-color: #e6e6e6;

      &:hover,
      &.active {
        background: #000;
        color: #fff;

        &:before {
          background-color: #e6e6e6 !important;
        }
      }

      &:hover + .item,
      &.active + .item {
        &:before {
          background-color: #e6e6e6 !important;
        }
      }
    }
  }

  &.small {
    padding: 3px;

    .item {
      padding: 3px 7px;
      width: auto;
      height: auto;
      border-radius: 5px;

      &:not(&:first-child):before {
        height: 15px;
        top: 4px;
      }
    }
  }

  .item {
    width: 52px;
    height: 32px;
    background: #000;
    border-radius: 4px;
    font-size: 13px;
    line-height: 16px;
    color: #f5f5f5;
    border: 2px solid #000;
    cursor: pointer;
    margin: 0 3px;
    transition: background-color 0.3s, color 0.3s;
    position: relative;

    &:not(&:first-child):before {
      position: absolute;
      content: '';
      display: block;
      width: 2px;
      height: 20px;
      background: #7a7a7a;
      left: -6px;
      top: 5px;
      transition: background-color 0.3s;
    }

    &:hover,
    &.active {
      background: #f5f5f5;
      color: #000;

      &:before {
        background-color: #000 !important;
      }
    }

    &:hover + .item,
    &.active + .item {
      &:before {
        background-color: #000 !important;
      }
    }
  }
}
