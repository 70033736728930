@import 'styles/varibles.scss';

.mask {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  &.mask-enter {
    opacity: 0;
  }

  &.mask-enter-active {
    opacity: 1;
    transition: opacity 0.3s;
  }

  &.mask-exit {
    opacity: 1;
  }

  &.mask-exit-active {
    opacity: 0;
    transition: opacity 0.3s;
  }
}
