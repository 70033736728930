.history {
  .container {
    height: 425px;
  }

  .simple {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 37px;
    color: #000;
    margin: 0 0 10px;
    display: flex;
    align-items: center;

    > span {
      border: 2px solid #000;
      box-sizing: border-box;
      border-radius: 8px;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 30px;
      padding: 2px 8px;
      margin-left: 12px;
    }

    > .icon {
      width: 37px;
      height: 37px;
      border-radius: 50%;
      margin-left: 12px;
    }
  }

  .pair {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 37px;
    margin: 0 0 10px;

    span {
      font-size: 24px;
    }

    a {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .split {
    color: #bfbfbf;
    font-size: 24px;
    margin: 0 7px;
    position: relative;
    top: -2px;

    &::after {
      content: '/';
    }
  }

  .date {
    margin: 10px 0 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #000;
  }

  .diff {
    color: #7a7a7a;
    margin: 5px 0 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    display: flex;
    height: 21px;
    align-items: center;

    .value {
      &.up {
        color: #1dd200;
      }

      &.down {
        color: #ff295d;
      }
    }

    .icon {
      font-size: 16px;
      position: relative;
      top: 4px;
      margin-right: 3px;
      transform: rotate(0deg);

      &.up {
        top: 2px;
        transform: rotate(180deg);
      }
    }

    .date-range {
      text-transform: uppercase;
      letter-spacing: 0.02em;
      text-decoration: underline;
      margin-left: 12px;
    }
  }

  .amount {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 45px;
    color: #000;
  }

  .footer {
    text-align: right;
    margin-top: 25px;
  }
}

.error {
  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
  }

  .chart {
    height: 257px;
    width: 600px;
    margin-top: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #7a7a7a;
  }
}

.skeleton {
  .title {
    width: 179px;
    height: 28px;
    margin-bottom: 16px;
  }

  .diff {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
  }

  .text {
    height: 11px;
    margin-right: 12px;
  }

  .loading {
    margin-right: 6px;
    width: 20px;
    height: 20px;
  }

  .chart {
    height: 257px;
    width: 600px;
    margin-top: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.02em;
    color: #7a7a7a;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
  }

  @keyframes skeleton-loading {
    0% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0 50%;
    }
  }

  .animation {
    background: linear-gradient(
      90deg,
      rgba(190, 190, 190, 0.2) 25%,
      rgba(129, 129, 129, 0.24) 37%,
      rgba(190, 190, 190, 0.2) 63%
    );
    background-size: 400% 100%;
    animation: skeleton-loading 1.4s ease infinite;
  }
}
