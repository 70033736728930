.container {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}

.checkbox {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 2px solid #000;
  box-sizing: border-box;
  cursor: pointer;
  background: #fff;

  input {
    display: none;
  }

  &::after {
    position: absolute;
    content: '';
    display: block;
    height: 5px;
    width: 8px;
    border-left: 2px solid #b47cff;
    border-bottom: 2px solid #b47cff;
    top: 1px;
    left: 1px;
    transform: rotate(-45deg);
    opacity: 0;
  }

  &:hover {
    border-color: #bfbfbf;
  }

  &.checked {
    background: #000;

    &:hover {
      border-color: #000;
    }

    &::after {
      opacity: 1;
    }
  }
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #000;
  margin-left: 6px;
  cursor: pointer;
}
