@import 'styles/varibles.scss';

$width: 168px;

.icon {
  margin-right: 6px;
  height: 20px;
  width: 20px;
}

.network {
  width: $width;
  border: 2px solid #000;
  box-sizing: border-box;
  border-radius: 8px;
  height: 42px;
  position: relative;
  padding: 0 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;

  > span {
    position: relative;
    z-index: 2;
    flex: 1;
  }

  .short {
    display: none;
  }

  @media (min-width: $screen-xs) {
    &.active {
      background: #fff;
      border-bottom-color: #fff;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  @media (max-width: $screen-xs) {
    padding: 0;
    width: auto;
    border: none;
    font-size: 16px;
    line-height: 20px;

    .arrow {
      font-size: 12px;
      margin-left: 4px;
      transform: rotate(180deg);
    }

    .short {
      display: block;
    }

    .long {
      display: none;
    }
  }

  .arrow {
    font-size: 16px;
    margin-left: 5px;
    transform: rotate(180deg);
  }
}

.popup {
  padding: 4px;
  background: #fff;
  border: 2px solid #000;
  border-top: none;
  box-sizing: border-box;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: $width;
  position: absolute;
  top: calc(100% - 2px);
  left: 0;
  z-index: 2;

  .item {
    border-radius: 3px;
    color: #7a7a7a;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    height: 42px;
    line-height: 42px;
    padding: 0 10px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      background: #f5f5f5;
    }

    &.active {
      color: #000;
      background: url('~assets/icons/checked.svg') center right 10px no-repeat;
      background-size: 16px 16px;
    }
  }

  @media (max-width: $screen-xs) {
    margin-top: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-top: 2px solid #000;
  }
}
