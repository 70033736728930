.currency {
  width: 30px;
  height: 30px;
  position: relative;

  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }

  .network {
    position: absolute;
    bottom: -6px;
    right: -8px;
    padding: 3px;
    background: #fff;
    border-radius: 50%;
    line-height: 1;
    height: 22px;
    width: 22px;
    box-sizing: border-box;

    svg {
      height: 16px;
      width: 16px;
      vertical-align: top;
    }
  }
}
