@font-face {
  font-family: 'Open Sauce One';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('~assets/fonts/OpenSauceOne-Light.woff') format('woff');
}

@font-face {
  font-family: 'Open Sauce One';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('~assets/fonts/OpenSauceOne-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sauce One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('~assets/fonts/OpenSauceOne-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Open Sauce One';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('~assets/fonts/OpenSauceOne-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sauce One';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('~assets/fonts/OpenSauceOne-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Open Sauce One';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('~assets/fonts/OpenSauceOne-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sauce One';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('~assets/fonts/OpenSauceOne-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Open Sauce One';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('~assets/fonts/OpenSauceOne-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sauce One';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('~assets/fonts/OpenSauceOne-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Open Sauce One';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('~assets/fonts/OpenSauceOne-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sauce One';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('~assets/fonts/OpenSauceOne-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Open Sauce One';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('~assets/fonts/OpenSauceOne-ExtraBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sauce One';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('~assets/fonts/OpenSauceOne-Black.woff') format('woff');
}

@font-face {
  font-family: 'Open Sauce One';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('~assets/fonts/OpenSauceOne-BlackItalic.woff') format('woff');
}
